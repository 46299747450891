<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-row no-gutters>
        <v-col cols="12" md="10">
          <div class="mt-2 txt_search">
            <v-text-field
              v-model="find"
              hide-details
              solo
              flat
              background-color="#CFE1E1"
              class="rounded-lg"
              placeholder="Search for people"
              prepend-inner-icon="mdi-magnify"
              @keyup="fetchData"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-end">
          <div class="mt-2 d-flex align-center">
            <h4 class="mr-2">Show</h4>
            <v-menu
              v-model="menuLimit"
              elevation="0"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-width="60px"
                  max-width="60px"
                  outlined
                  depressed
                  class="text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                >
                  <p class="ma-0 default_txt">{{ limit }}</p>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card class="popup_radius" outlined elevation="0">
                <v-list>
                  <v-list-item link @click="changeLimit(10)">
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(50)">
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(100)">
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4" align="center">
        <v-col cols="12" md="4" class="py-2">
          <div class="d-flex align-center">
            <bullet :title="'List Invoice'" class="mr-2" />
          </div>
        </v-col>
        <v-col cols="12" md="8">
          <v-row no-gutters align="center" justify="end">
            <div class="col_tollbar">
              <v-btn
                depressed
                class="text-capitalize btn_tollbar rounded-lg bg_color1"
                dark
                to="/admin/mku/invoice/create"
              >
                <v-icon class="mr-2"> mdi-plus</v-icon> Tambah
              </v-btn>
            </div>
            <div class="col_tollbar">
              <v-menu
                :close-on-content-click="false"
                v-model="sort_menu"
                transition="slide-y-transition"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    dark
                    color="#CFE1E1"
                    class="text-capitalize btn_tollbar rounded-lg"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#106967"> {{ icon_sort }}</v-icon>
                    <h4 class="ml-2" style="color: #106967">
                      {{
                        sort
                          ? sort == "tgl_transaksi"
                            ? "Tanggal Transaksi"
                            : "ID Invoice"
                          : "Sort"
                      }}
                    </h4>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <div class="d-flex align-center">
                      <v-btn
                        @click="
                          changedesc(
                            icon_sort == 'mdi-sort-ascending'
                              ? 'mdi-sort-descending'
                              : 'mdi-sort-ascending'
                          )
                        "
                        block
                        class="text-capitalize"
                        text
                      >
                        <v-icon class="mr-2">{{ icon_sort }}</v-icon>
                        Sort
                      </v-btn>
                    </div>
                    <v-btn @click="sort_menu = false" icon fab x-small>
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      block
                      :class="
                        sort == 'tgl_transaksi'
                          ? 'text-capitalize grey'
                          : 'text-capitalize white'
                      "
                      :dark="sort == 'tgl_transaksi' ? true : false"
                      text
                      @click="changeSort('tgl_transaksi')"
                    >
                      Tanggal Transaksi
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      block
                      :class="
                        sort == 'id'
                          ? 'text-capitalize grey'
                          : 'text-capitalize white'
                      "
                      :dark="sort == 'id' ? true : false"
                      text
                      @click="changeSort('id')"
                    >
                      ID Invoice
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-skeleton-loader
        class="pa-2 elevation-0"
        type="table-tbody"
        v-if="loading"
      ></v-skeleton-loader>
      <div v-if="!loading && dataSet">
        <v-data-table
          :headers="headers"
          :items="dataSet"
          hide-default-footer
          :page.sync="page"
          :items-per-page="limit"
          class="elevation-0"
          :single-select="false"
        >
          <template v-slot:[`item.total_tagihan`]="{ item }">
            <div v-if="item.total_tagihan" class="d-flex">
              Rp.
              {{ formatRupiah(item.total_tagihan) }}
            </div>
          </template>
          <template v-slot:[`item.absensi.tgl_absen`]="{ item }">
            <div v-if="item.absensi" class="d-flex">
              {{
                $date(new Date(item.absensi.tgl_absen)).format("MMM DD, YYYY")
              }}
            </div>
          </template>
          <template v-slot:[`item.klien_costumer_id`]="{ item }">
            <div class="d-flex text-capitalize">
              {{ checkName(item.klien_costumer_id) }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div>
              <v-card
                flat
                class="py-1 px-2 rounded"
                :color="item.status == 1 ? '#106967' : '#DC3545'"
              >
                <p
                  class="medium_txt pa-0 ma-0 text-capitalize"
                  style="color: white; text-align: center"
                >
                  {{ item.status == 1 ? "Done" : "Belum" }}
                </p>
              </v-card>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <!-- :disabled="item.status == 1 ? true : false" -->
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    icon
                    depressed
                    class="mr-2"
                    :to="`/admin/mku/invoice/update/${item.id}`"
                  >
                    <v-icon small> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <span>Edit Invoice</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    icon
                    depressed
                    class="mr-2"
                    :to="`/admin/mku/invoice/detail/${item.id}`"
                  >
                    <v-icon small> mdi-eye </v-icon>
                  </v-btn>
                </template>
                <span>Detail Invoice</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    icon
                    depressed
                    color="red"
                    @click="deleteData(item)"
                  >
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Delete Invoice</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pa-3">
          <v-pagination
            color="#052633"
            v-model="page"
            @input="fetchData"
            :length="last_page"
          ></v-pagination>
        </div>
      </div>
    </div>
    <div class="pa-2">
      <dialog-small
        :d_small="d_small"
        @refetch="fetchData"
        @close="d_small = false"
        :inventory="inventory"
        :type="d_type"
        :title="d_title"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { bullet, NavbarProfile },
  name: "listInvoice",
  computed: {
    ...mapState({
      role: (state) => state.role,
      profile: (state) => state.profile,
    }),
  },
  data() {
    return {
      menuLimit: false,
      find: "",
      page: 1,
      limit: 10,
      dataSet: null,
      last_page: null,
      total_page: null,
      import_data: false,
      loading: false,
      d_large: false,
      d_create: false,
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      sort: "",
      sort_menu: false,
      icon_sort: "mdi-sort-ascending",
      menuCustom_date: false,
      custom_date: "",
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Invoice",
          disabled: true,
          href: "#",
        },
      ],
      headers: [
        { text: "Tanggal", value: "tgl_transaksi", width: "13%" },
        { text: "ID", value: "id", sortable: false },
        {
          text: "Nama",
          value: "klien_costumer_id",
          width: "25%",
          sortable: false,
        },
        {
          text: "Tanggal Jatuh Tempo",
          value: "due_date",
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
        { text: "Notes", value: "note", sortable: false },
        {
          text: "Total Tagihan",
          value: "total_tagihan",
          sortable: false,
        },
        { text: "Action", value: "actions", sortable: false },
      ],
      customer: null,
    };
  },
  mounted() {
    this.fetchDataCustomer();
  },
  methods: {
    formatRupiah(angka) {
      var temp_angka = angka.toString().replace(".", ",");
      var number_string = temp_angka,
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

      if (ribuan) {
        let separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return rupiah;
    },
    checkName(id) {
      let name = "-";
      for (let i = 0; i < this.customer.length; i++) {
        if (this.customer[i].id == id) {
          name = this.customer[i].nama_lengkap;
          break;
        }
      }
      return name;
    },

    fetchDataCustomer() {
      this.loading = true;
      let data = {
        path: `sysadmin/customer/list`,
      };
      this.$store
        .dispatch("officer/getData", data)
        .then((data) => {
          this.customer = data.data;
          this.fetchData();
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/admin/invoice/get?page[size]=${
          this.limit
        }&page[number]=${this.page}&sort=${
          this.sort ? (this.icon_sort == "mdi-sort-ascending" ? "-" : "+") : ""
        }${this.sort}&filter[nama]=${this.find}`,
      };
      this.$store
        .dispatch("fileData/getData", data)
        .then((data) => {
          this.dataSet = data.data.data;
          this.last_page = data.data.last_page;
          this.total_page = data.data.total;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    changeSort(item, add) {
      if (item == "custom_date" && !add) {
        this.menuCustom_date = true;
      } else if (item == "custom_date" && add == "hide") {
        this.menuCustom_date = false;
      }
      this.sort = item;
      this.sort_menu = false;
      this.fetchData();
    },
    changedesc(item) {
      this.icon_sort = item;
      this.fetchData();
    },
    deleteData(item) {
      this.inventory = {
        data: item,
        path: `sysadmin/admin/invoice/delete`,
        deleteType: "Invoice",
      };
      this.d_type = "delete";
      this.d_title = "Hapus Invoice";
      this.d_small = true;
      this.line = true;
    },
    changeLimit(val) {
      this.limit = val;
      this.fetchData();
    },
  },
};
</script>

<style scoped>
.v-pagination__item {
  box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
}
</style>
